<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" class="text-light" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item v-if="isCabang" href="https://kasir.armezza.com" 
    target="_blank" rel="noopener noreferrer"
    v-b-tooltip.hover.top="'Kasir'" class="text-white mr-1">
        <feather-icon size="21" class="text-white" icon="MonitorIcon" />
      </b-nav-item>
      <b-nav-item v-b-tooltip.hover.top="'Refresh'" @click.prevent="reloadPage" class="text-white mr-1">
        <feather-icon size="21" class="text-white" icon="RefreshCwIcon" />
      </b-nav-item>
      <locale />
      <dark-Toggler v-b-tooltip.hover.top="'Mode Gelap'" class="d-none d-md-block" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItem, VBTooltip } from "bootstrap-vue";
import SearchBar from "./components/SearchBar.vue";
import Locale from './components/Locale.vue'
import DarkToggler from "./components/DarkToggler.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";

export default {
  components: {
    Locale,
    BLink,
    BNavbarNav,
    BNavItem,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
