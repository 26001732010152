var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container d-flex content align-items-center"
  }, [_c('ul', {
    staticClass: "nav navbar-nav d-xl-none"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "nav-link",
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }, [_c('feather-icon', {
    staticClass: "text-light",
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
  }), _c('b-navbar-nav', {
    staticClass: "nav align-items-center ml-auto"
  }, [_vm.isCabang ? _c('b-nav-item', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: 'Kasir',
      expression: "'Kasir'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "text-white mr-1",
    attrs: {
      "href": "https://kasir.armezza.com",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_c('feather-icon', {
    staticClass: "text-white",
    attrs: {
      "size": "21",
      "icon": "MonitorIcon"
    }
  })], 1) : _vm._e(), _c('b-nav-item', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: 'Refresh',
      expression: "'Refresh'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "text-white mr-1",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.reloadPage($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "text-white",
    attrs: {
      "size": "21",
      "icon": "RefreshCwIcon"
    }
  })], 1), _c('locale'), _c('dark-Toggler', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: 'Mode Gelap',
      expression: "'Mode Gelap'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "d-none d-md-block"
  }), _c('user-dropdown')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }